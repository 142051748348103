.list_item {
  cursor: pointer;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.rankIcon {
  background-color: rgb(153, 152, 152);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
  font-size: 10px;
}

.rankIcon:hover {
  transform: scale(1.1);
  box-shadow: 0 0 5px rgba(176, 176, 176, 0.8);
  animation: pulse 0.2s ease-out;
}

.active_icon {
  background-color: #24c3b5;
}

.active_text span {
  color: #009c8e;
  font-weight: bold !important;
}

.removeBtn {
  visibility: hidden;
  color: gray;
}

.list_item:hover .removeBtn {
  visibility: visible;
}