.actions button {
  margin-left: 10px;
}
.update_rank {
  margin-left: 5px;
}
.rotate {
  margin-left: 5px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
