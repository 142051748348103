.columns_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 8px;
    width: 100%;
}

.each_column {
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 8px;
    min-width: 120px;
}

.each_subrow_container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 6px;
    margin-top: 6px;
}

.each_subrow {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 2px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}