.list_item {
    height: 1.875rem;
    padding: 1.563rem 0.875rem !important;
    cursor: pointer;
}

.list_item:hover {
    background-color: transparent;
}

.list_item span {
    padding-left: 20px;
}

.link {
    padding: 0.313rem 0.5rem;
    font-size: 1.125rem;
    color: #000;
}
