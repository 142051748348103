.main-value {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2;
  width: 35px;
}

.up-icon {
  margin-top: 3px;
  font-size: 12px;
  /* width: 10px; */
  /* margin-left: 2px; */
}

.down-icon {
  margin-bottom: 5.5px;
  font-size: 12px;
  /* width: 10px; */
  /* margin-left: 2px; */
}

.delta {
  font-size: 12px;
  line-height: 1.2;
  margin-top: 1px;
  /* width: 15px; */
  color: "#575757";
  /* margin-left: -4px; */
}

.core-main-value {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.2;
  width: 20px;
}

.core-up-icon {
  margin-top: 5px;
  font-size: 13px;
}

.core-down-icon {
  margin-bottom: 5px;
  font-size: 13px;
}

.coreDelta {
  font-size: 14px;
  line-height: 1.2;
  margin-top: 1px;
}

.comp-logo-header {
  width: 100px;
  vertical-align: top;
}

.comp-logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.logo {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  margin-bottom: 5px;
}

.section_summary {
  display: grid;
  grid-template-columns: 2.05fr 1fr;
  gap: 1.25rem;
  background-color: white;
  padding: 1.1rem;
  border-radius: 8px;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
}

.regenerate {
  color: #007aff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.mainSubScore {
  font-size: 1.225rem;
  font-weight: 600;
}

.mainScore {
  font-weight: 700;
  font-size: 2.83rem;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.5rem;
  cursor: pointer;
  color: grey;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.5rem;
  cursor: pointer;
  color: grey;
}

.score {
  font-weight: 700;
  font-size: 1.5rem;
  position: relative
}

.subScoreRank,
.subScoreFav {
  font-size: 0.9rem;
  font-weight: 600;
  position: relative;
}

.score:hover,
.subScoreFav:hover,
.subScoreRank:hover {
  color: black;
}


.divider {
  margin: 0.25rem 0;
}

.table_head_title {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}

.table_head {
  background: #eff4f8;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid white !important;
}

.table_head_cell {
  padding-top: 0.938rem !important;
  padding-bottom: 0.625rem !important;
  border-bottom: 1px solid white;
}

.table_head_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table_head_text {
  font-size: 0.875rem;
  color: #808080;
  font-weight: 300;
  padding-top: 0.313rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 7.5rem;
}

.table_head_text_comp {
  font-size: 1.25rem;
  color: #000;
  font-weight: 500;
}

.table_head_logo {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 6px;
}

.attribute_headings {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: #333 !important;
  text-align: center !important;
}

.attribute_score {
  font-size: 1rem !important;
  text-align: center !important;
  font-weight: 700 !important;
  cursor: pointer !important;
}